<template>
  <div>
    <b-card>
      <div class="d-flex flex-wrap justify-content-between pt-2 align-items-start">
        <div class="d-flex align-items-center float-left">
          <a class=" mr-50" @click="$router.go(-1)">
            <feather-icon icon="ChevronLeftIcon" size="20" />
          </a>
          <h3 class="m-0 font-weight-600">
            {{ appTitle || $t("articles.title") }}
          </h3>
        </div>
        <!-- Search -->
        <b-form-input
          v-model="searchInput"
          type="text"
          class="d-lg-none mr-2 my-1 mb-lg-3"
          :placeholder="$t('form-create-item.search-input')"
          @input="searchItems"
        />
        <b-form-input
          v-model="searchInput"
          type="text"
          class="d-none d-lg-block mr-2 mb-1 mb-lg-3"
          style="width:450px;"
          :placeholder="$t('form-create-item.search-input')"
          @input="searchItems"
        />
        <b-button
          v-if="isStaff || canAdd"
          class="float-right mr-1"
          variant="outline-primary"
        >
          <b-link :to="{ name: 'articlesCreate' }">
            {{ $t("articles.create-title") }}
          </b-link>
        </b-button>
      </div>

      <!-- Loading -->
      <div v-if="isLoading">
        <b-spinner type="grow" small class="mx-auto mt-3 d-block text-primary" />
      </div>

      <!-- Container with data -->
      <div v-else-if="items.length > 0" class="cards-container">
        <div>
          <!-- Items Loop -->
          <div
            v-for="item in items"
            :key="item.key"
            class="x-scroll-element mx-1 mb-3 p-0 "
          >
            <b-link v-if="!item.parentKey" :to="{ name: 'article', params: { id: item.key } }" class="link">
              <b-card
                :key="item.key"
                class="x-scroll-element mx-1 mb-3 p-0 card-article float-left"
              >
                <h4 v-if="item.name" class="min-height-3 html-text-ellipsis-2">
                  {{ item.name[currentLocale] || Object.values(item.name)[0] }}
                </h4>
                <b-media-body v-if="item.ownedByMember" class="mb-2">
                  <small class="text-muted mr-50">{{
                    $t("articles.created-by")
                  }}</small>
                  <small>
                    <b-link class="text-body">
                      {{ item.ownedByMember.name }}
                    </b-link>
                  </small>
                  <span class="text-muted ml-75 mr-50">|</span>
                  <small class="text-muted">{{
                    timestampToTime(item.createdAt)
                  }}</small>
                </b-media-body>
                <b-card-text>
                  <p
                    v-if="item.body"
                    class="min-height-5 content-container"
                    v-html="
                      item.body[currentLocale] || Object.values(item.body)[0]
                    "
                  />
                  <p v-else class="min-height-3" />
                </b-card-text>
                <!-- <b-card-text v-if="item.createdAt">
                  <small class="text-muted"> {{ timestampToTime(item.createdAt) }}</small>
                </b-card-text> -->
              </b-card>
            </b-link>
          </div>
        </div>

        <!-- Load More items -->
        <div
          v-if="items.length < itemsData.meta.total"
          class="w-100 float-left"
        >
          <b-button
            v-if="!isLoadingNextPage"
            class="center-x my-3"
            variant="outline-primary"
            @click="handleLoadOfNewItems"
          >
            {{ $t("action.load-more") }}
          </b-button>
          <div v-else class="w-100">
            <b-spinner
              class="center-x my-3"
              small
              variant="primary"
              type="grow"
              label="Loading..."
            />
          </div>
        </div>
      </div>

      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="blogPlaceholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("available.message", { itemName: "items" }) }}
          </p>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { getImageResource } from '@/@core/utils/image-utils';
import { quillEditor } from 'vue-quill-editor';
import FileTypes from '@/@core/constants/FileTypes';
import BlogPlaceholder from '@/assets/images/placeholders/light/blog.svg';
import moment from 'moment';
import Placeholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { checkPermissions } from '@/@core/utils/roles-utils';

export default {
  name: 'ArticlesList',
  components: {
    quillEditor,
  },
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      formInput: {},
      formError: null,
      itemImage: null,
      imageSrc: null,
      Placeholder,
      searchInput: '',
      timer: null,
    };
  },
  computed: {
    FileTypes() {
      return FileTypes;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return checkPermissions(
        'create',
        'forum',
        this.loggedMemberRoles,
        this.collective,
      );
    },
    itemsData() {
      return this.$store.getters.articles.articles;
    },
    items() {
      if (
        !this.itemsData
        || this.itemsData.length === 0
        || !this.itemsData.unpaginated
      ) {
        return [];
      }
      return this.itemsData.unpaginated;
    },
    hasMeetingsSlotsAddOn() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]
        .meetingsSlots;
    },
    hasMeetingAddOn() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].meetings;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    hasChatIndividualAddOn() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]
        .chatIndividual;
    },
    blogPlaceholder() {
      return BlogPlaceholder;
    },
    app() {
      return this.$store.getters.apps.apps[this.appId];
    },
    appId() {
      return 104;
    },
    appTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.app?.customizationName,
      );
    },
  },
  watch: {
    itemImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData({ forceApiCall: false });
    this.isLoading = false;
  },
  methods: {
    timestampToTime(timestamp) {
      return moment(timestamp).format('DD-MM-YYYY');
    },
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    async fetchData({ searchString = '', forceApiCall = false }) {
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        itemType: 'articles',
        storedKey: 'articles',
        forceAPICall: forceApiCall,
        page: this.lastLoadedPage,
        requestConfig: {
          searchString,
          count: 16,
          orderByDate: -1,
        },
      });
      this.updateBreadcrumbs();
      this.isLoading = false;
    },

    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData({ forceApiCall: true });
        this.isLoadingNextPage = false;
      }
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: this.appTitle || this.$t('articles.title'),
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    searchItems(page = { page: 1 }) {
      this.lastLoadedPage = page.page;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.fetchData({
          searchString: this.searchInput,
          forceApiCall: true,
        });
      }, 800);
    },
  },
};
</script>
<style scoped>
.content-container img {
  max-width: 100%;
}
/* TODO: fix responsive with bootstrap, better than media queries!! */
@media only screen and (max-width: 600px) {
  .cards-container {
    margin-left: -2rem;
  }
}

.card-img {
  height: 200px !important;
}
.link {
  color: #6e6b7b !important;
}
</style>
